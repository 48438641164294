import Box from '@mui/material/Box'
import { useState, useCallback, useRef } from 'react'
import IdleOverlay from './IdleOverlay'
import PlayingOverlay from './PlayingOverlay'
import EndedOverlay from './EndedOverlay'

const [
  LOADING, // before the canplay event fires
  IDLE, // not started yet OR paused
  PLAYING, // during playback
  ENDED, // ended OR paused within STING_LENGTH of the end
] = [0, 1, 2, 3]

const Player = ({ reclip, audioUrl }) => {
  const audioRef = useRef(null)
  const [overlay, setOverlay] = useState(LOADING)
  const [time, setTime] = useState(reclip.start)

  const play = useCallback(() => {
    audioRef.current.play()
  }, [])

  const replay = useCallback(() => {
    audioRef.current.currentTime = 0
    play()
  }, [play])

  const pause = useCallback(() => {
    audioRef.current.pause()
  }, [])

  const onCanPlay = useCallback(() => {
    if (overlay !== PLAYING) setOverlay(IDLE)
  }, [overlay])

  const onPlay = useCallback(() => {
    setOverlay(PLAYING)
  }, [])

  const onPause = useCallback(() => {
    setOverlay(IDLE)
  }, [])

  const onEnded = useCallback(() => {
    setOverlay(ENDED)
  }, [])

  const onTimeUpdate = useCallback(() => {
    setTime(audioRef.current.currentTime + reclip.start)
  }, [reclip])

  const coverPhotoUrl = reclip.cover_photo?.is_visible
    ? reclip.cover_photo?.url
    : null

  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        position: 'relative',
        borderRadius: '12px',
        overflow: 'hidden',
        '& audio': {
          position: 'absolute',
          top: 0,
          left: 0,
          visibility: 'hidden',
        },
      }}
    >
      <audio
        ref={audioRef}
        preload="auto"
        onPlay={onPlay}
        onPause={onPause}
        onEnded={onEnded}
        onTimeUpdate={onTimeUpdate}
        onCanPlay={onCanPlay}
        controls
      >
        <source src={audioUrl} type="audio/x-wav" />
        Your browser does not support the audio element.
      </audio>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          background: `linear-gradient(to bottom, ${reclip.background_color1}, ${reclip.background_color2})`,
          backgroundImage: coverPhotoUrl ? `url(${coverPhotoUrl})` : undefined,
          backgroundSize: 'cover',
        }}
      >
        {(() => {
          switch (overlay) {
            case LOADING:
              return null
            case IDLE:
              return <IdleOverlay play={play} reclip={reclip} />
            case PLAYING:
              return (
                <PlayingOverlay pause={pause} reclip={reclip} time={time} />
              )
            case ENDED:
              return <EndedOverlay replay={replay} reclip={reclip} />
            default:
              return null
          }
        })()}
      </Box>
    </Box>
  )
}

export default Player
