import React, { useCallback, useContext, useEffect } from 'react'
import { ImageDropzone } from 'react-file-utils'
import {
  ChatAutoComplete,
  EmojiPicker,
  UploadsPreview,
  useChannelStateContext,
  useMessageInputContext,
} from 'stream-chat-react'

import { GiphyContext } from '../'
import { LightningBoltSmall } from '../../assets'

import './MessagingInput.css'
import Box from '@mui/material/Box'
import EmojiIcon from '@mui/icons-material/Mood'
import SendIcon from '@mui/icons-material/Send'
import IconButton from '@mui/material/IconButton'

import MessageContext from 'components/Main/Chat/MessageContext'

const GiphyIcon = () => (
  <div className="giphy-icon__wrapper" style={{ marginRight: '16px' }}>
    <LightningBoltSmall />
    <p className="giphy-icon__text">GIPHY</p>
  </div>
)

const MessagingInput = () => {
  const { giphyState, setGiphyState } = useContext(GiphyContext)
  const { acceptedFiles, maxNumberOfFiles, multipleUploads } =
    useChannelStateContext()

  const messageInput = useMessageInputContext()
  const onChangeMessageInput = useContext(MessageContext)

  useEffect(() => {
    if (onChangeMessageInput) onChangeMessageInput(messageInput)
  }, [
    onChangeMessageInput,
    messageInput.text,
    messageInput.imageUploads,
    messageInput.fileUploads,
  ])

  const onChange = useCallback(
    (event) => {
      const { value } = event.target
      const deletePressed =
        event.nativeEvent?.inputType === 'deleteContentBackward'

      if (messageInput.text.length === 1 && deletePressed) {
        setGiphyState(false)
      }

      if (
        !giphyState &&
        messageInput.text.startsWith('/giphy') &&
        !messageInput.numberOfUploads
      ) {
        event.target.value = value.replace('/giphy', '')
        setGiphyState(true)
      }

      messageInput.handleChange(event)
    },
    [giphyState, messageInput.numberOfUploads, messageInput.text] // eslint-disable-line
  )

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        padding: '8px',
        position: 'relative',
        '& textarea': {
          backgroundColor: 'transparent',
          borderColor: 'divider',
          borderWidth: '1px',
          borderStyle: 'solid',
          color: 'text.primary',
          lineHeight: '20px',
          '&:focus': {
            backgroundColor: 'background.paper',
            borderColor: 'divider',
          },
        },
      }}
    >
      <ImageDropzone
        accept={acceptedFiles}
        handleFiles={messageInput.uploadNewFiles}
        multiple={multipleUploads}
        disabled={
          (maxNumberOfFiles !== undefined &&
            messageInput.numberOfUploads >= maxNumberOfFiles) ||
          giphyState
        }
      >
        <UploadsPreview />
        <div className="messaging-input__container">
          <IconButton
            title="Pick Emoji"
            onClick={messageInput.openEmojiPicker}
            ref={messageInput.emojiPickerRef}
          >
            <EmojiIcon />
          </IconButton>
          <div className="messaging-input__input-wrapper">
            {giphyState && !messageInput.numberOfUploads && <GiphyIcon />}

            <ChatAutoComplete
              onChange={onChange}
              rows={1}
              placeholder="Send a message"
            />
          </div>
          {!onChangeMessageInput && (
            <IconButton
              title="Send Message"
              onClick={messageInput.handleSubmit}
            >
              <SendIcon />
            </IconButton>
          )}
        </div>
      </ImageDropzone>
      <EmojiPicker />
    </Box>
  )
}

export default React.memo(MessagingInput)
