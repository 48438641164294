import Box from '@mui/material/Box'

const ModelSelector = ({ model, models, setModel }) => {
  return (
    <Box
      sx={{
        p: 0.75,
        backgroundColor: 'background.paper',
        borderRadius: 2,
        maxWidth: '250px',
        '& select': {
          backgroundColor: 'transparent',
          border: 0,
          outline: 0,
          width: '100%',
          color: 'text.primary',
          fontWeight: 700,
        },
      }}
    >
      <select onChange={(e) => setModel(e.target.value)} value={model}>
        {models.map((m) => (
          <option key={m} value={m}>
            {m}
          </option>
        ))}
      </select>
    </Box>
  )
}

export default ModelSelector
