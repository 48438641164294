import { useState, useCallback } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton'
import { TextArea, Text, Header } from './Bits'

const Test = ({
  testConfig,
  testResponse,
  isTestingConfig,
  clearTestResponse,
}) => {
  const [text, setText] = useState('')

  const handleClear = useCallback(() => {
    setText('')
    clearTestResponse()
  }, [clearTestResponse])

  const openSearchPage = useCallback(() => {
    window.open('/search', '_blank')
  }, [])

  return (
    <Box>
      <Box sx={{ fontSize: '1.2em', fontWeight: 700, mb: 2 }}>
        Test The Config
      </Box>
      <Text>
        Test the configuration by entering the transcript of a reclip (real or
        made-up). You can find the transcripts of real reclips on the{' '}
        <Box
          sx={{
            display: 'inline',
            textDecoration: 'underline',
            cursor: 'pointer',
          }}
          onClick={openSearchPage}
        >
          search
        </Box>{' '}
        page.
      </Text>
      <TextArea onChange={setText} value={text} rows={8} />
      <Box
        sx={{ mt: 2, mb: 4, display: 'flex', justifyContent: 'space-between' }}
      >
        <Button variant="outlined" onClick={handleClear} disabled={!text}>
          Clear
        </Button>
        <LoadingButton
          variant="contained"
          onClick={testConfig.bind(null, text)}
          disabled={!text}
          loading={isTestingConfig}
        >
          Test
        </LoadingButton>
      </Box>
      {testResponse && (
        <Box sx={{ mt: 4 }}>
          <Header text={testResponse.error ? 'GPT Error' : 'GPT Response'} />
          <Box
            sx={{
              backgroundColor: 'background.paper',
              whiteSpace: testResponse.error ? 'pre-wrap' : 'pre',
              fontSize: '0.9em',
              p: 2,
              mt: 2,
              borderRadius: '8px',
              maxHeight: '400px',
              overflow: 'auto',
            }}
          >
            <code>
              {testResponse.error ||
                JSON.stringify(testResponse.content, null, 2)}
            </code>
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default Test
