import { useEffect, useState } from 'react'
import api from 'services/api'
import Player from './Player'
import Loader from 'components/core/Loader'
import { ADMIN_API_URL } from 'const'
import { useAuth } from 'store/selectors'

const ReclipLoader = ({ source_id }) => {
  const [reclip, setReclip] = useState(null)
  const [audioUrl, setAudioUrl] = useState(null)
  const [error, setError] = useState(null)
  const { token } = useAuth()

  useEffect(() => {
    if (!source_id) return setError('Source ID not provided.')

    api.reclips
      .getReclip(source_id)
      .then(setReclip)
      .catch((e) => setError(e.message))
  }, [source_id])

  useEffect(() => {
    if (!source_id) return setError('Source ID not provided.')

    const audioUrl = `${ADMIN_API_URL}/reclips/${source_id}/audio`
    fetch(audioUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        const blobUrl = URL.createObjectURL(blob)
        setAudioUrl(blobUrl)
      })
  }, [source_id, token])

  if (!reclip || !audioUrl) return <Loader error={error} />

  return <Player reclip={reclip} audioUrl={audioUrl} />
}

export default ReclipLoader
