import { useLocation } from 'react-router-dom'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import ChatIcon from '@mui/icons-material/Chat'
import NetworksIcon from '@mui/icons-material/People'
import StaffIcon from '@mui/icons-material/Phone'
// import SettingsIcon from '@mui/icons-material/Settings'
import SearchIcon from '@mui/icons-material/Search'
import TranscriptionIcon from '@mui/icons-material/InterpreterMode'
import SwaggerIcon from '@mui/icons-material/Description'
import MapIcon from '@mui/icons-material/Map'
import GPTIcon from '@mui/icons-material/Psychology'
import LogoutIcon from '@mui/icons-material/Logout'
import DarkModeIcon from '@mui/icons-material/DarkMode'
import LightModeIcon from '@mui/icons-material/LightMode'
import Divider from '@mui/material/Divider'
import { useNavigate } from 'react-router-dom'
import useSettingsActions from 'store/actions/settings'
import { useSettings } from 'store/selectors'
import useAuthActions from 'store/actions/auth'
import EnvironmentToggle from './EnvironmentToggle'

const ITEMS = [
  {
    path: '/chat',
    title: 'Chat',
    Icon: ChatIcon,
  },
  {
    path: '/networks',
    title: 'Networks',
    Icon: NetworksIcon,
  },
  // {
  //   path: '/map',
  //   title: 'Recent Clips',
  //   Icon: MapIcon,
  // },
  {
    path: '/staff',
    title: 'Staff Members',
    Icon: StaffIcon,
  },
  {
    path: '/transcription',
    title: 'Transcription',
    Icon: TranscriptionIcon,
  },
  {
    path: '/swagger',
    title: 'Swagger',
    Icon: SwaggerIcon,
  },
  {
    path: '/search',
    title: 'Search',
    Icon: SearchIcon,
  },
  {
    path: '/gpt',
    title: 'GPT',
    Icon: GPTIcon,
  },
  // {
  //   path: '/settings',
  //   title: 'Settings',
  //   Icon: SettingsIcon,
  // },
]

export const MainListItems = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  return (
    <>
      {ITEMS.map(({ path, title, Icon }) => {
        const active = pathname.startsWith(path)
        return (
          <ListItemButton
            key={path}
            sx={{
              backgroundColor: active ? 'action.focus' : undefined,
              cursor: active ? 'default' : undefined,
              marginBottom: 1,
              '&:hover': {
                backgroundColor: active ? 'action.focus' : undefined,
              },
            }}
            onClick={active ? undefined : navigate.bind(null, path)}
          >
            <ListItemIcon>
              <Icon />
            </ListItemIcon>
            <ListItemText primary={title} />
          </ListItemButton>
        )
      })}
    </>
  )
}

export const SecondaryListItems = () => {
  const { toggleTheme } = useSettingsActions()
  const { theme } = useSettings()
  const { logout } = useAuthActions()

  return (
    <>
      <EnvironmentToggle />
      <Divider sx={{ marginBottom: 1 }} />
      <ListItemButton onClick={toggleTheme} sx={{ marginBottom: 1 }}>
        <ListItemIcon>
          {theme === 'light' ? <DarkModeIcon /> : <LightModeIcon />}
        </ListItemIcon>
        <ListItemText
          primary={theme === 'light' ? 'Dark Mode' : 'Light Mode'}
        />
      </ListItemButton>
      <ListItemButton onClick={logout}>
        <ListItemIcon>
          <LogoutIcon />
        </ListItemIcon>
        <ListItemText primary="Log Out" />
      </ListItemButton>
    </>
  )
}
