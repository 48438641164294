import Box from '@mui/material/Box'
import DeleteButton from './DeleteButton'
import { toDateString, toTimeString } from 'services/utils'
import ViewButton from './ViewButton'

const ArchiveList = ({ broadcasts, deleting, onDelete }) => {
  return (
    <Box
      sx={{
        maxHeight: '600px',
        overflow: 'auto',
        fontSize: '0.9em',
        '& table': {
          borderCollapse: 'collapse',
          width: '100%',
        },
        '& th, td': {
          padding: 1,
          textAlign: 'left',
        },
        '& tbody tr:not(:last-of-type) td': {
          borderBottomWidth: 1,
          borderBottomColor: 'divider',
          borderBottomStyle: 'solid',
        },
        '& tbody tr td': {
          cursor: 'default',
        },
        '& th': {
          borderBottomWidth: 1,
          borderBottomColor: 'divider',
          borderBottomStyle: 'solid',
          whiteSpace: 'nowrap',
        },
      }}
    >
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Sent At</th>
            <th>Sender</th>
            <th>Segment Size</th>
            <th>Num Sent</th>
            <th>Num Errors</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {broadcasts.map(
            (
              {
                id,
                name,
                segment_type,
                segment_size,
                num_user_ids,
                scheduled_for,
                message,
                sent_messages,
                errored_messages,
              },
              index
            ) => (
              <tr key={id}>
                <td>{name}</td>
                <td>
                  <Box sx={{ whiteSpace: 'nowrap' }}>
                    {toDateString(scheduled_for) +
                      ' @ ' +
                      toTimeString(scheduled_for)}
                  </Box>
                </td>
                <td>{message.custom_data.staff_member}</td>
                <td>{segment_size}</td>
                <td>{sent_messages}</td>
                <td>{errored_messages}</td>
                <td>
                  <ViewButton message={message} />
                </td>
                <td>
                  <DeleteButton
                    isLoading={deleting.includes(id)}
                    onClick={onDelete.bind(null, id)}
                  />
                </td>
              </tr>
            )
          )}
        </tbody>
      </table>
      {broadcasts.length === 0 && (
        <Box sx={{ fontStyle: 'italic', mt: 4 }}>
          No broadcasts have been sent.
        </Box>
      )}
    </Box>
  )
}

export default ArchiveList
