export const identity = (x) => x

export const pick = (obj, keys) => {
  const out = {}
  keys.forEach((key) => (out[key] = obj[key]))
  return out
}

export const delay = async (time) => {
  return new Promise((resolve) => {
    setTimeout(resolve, time)
  })
}

export function toTimestamp(d) {
  return d === null || d === undefined ? d : +new Date(d + 'Z')
}

export function toDateString(d) {
  if (d === null || d === undefined) return d
  const date = new Date(toTimestamp(d))
  return date.toLocaleDateString().replace(/\d\d(\d\d)$/, '$1')
}

export function toTimeString(d) {
  if (d === null || d === undefined) return d
  const date = new Date(toTimestamp(d))
  return date.toLocaleTimeString().replace('AM', 'am').replace('PM', 'pm')
}

export function isDate(date) {
  return date instanceof Date && !isNaN(date.valueOf())
}
