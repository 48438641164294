import { useCallback } from 'react'
import Box from '@mui/material/Box'
import { Header, Text, TextArea } from './Bits'
import ModelSelector from './ModelSelector'

const ConfigEditor = ({ config, updateConfig, models }) => {
  const handleChange = useCallback(
    (field, value) => {
      updateConfig(field, value)
    },
    [updateConfig]
  )

  return (
    <Box>
      <Header text="Model" />
      <Text>
        The GPT model to use. The models in the dropdown are the ones capable of
        returning JSON.
      </Text>
      <ModelSelector
        model={config.model}
        models={models}
        setModel={handleChange.bind(null, 'model')}
      />

      <Header text="Instructions" />
      <Text>
        This is for general information provided to GPT about its role in
        responding to the prompt.
      </Text>
      <TextArea
        value={config.instructions}
        onChange={handleChange.bind(null, 'instructions')}
        rows={4}
      />
      <Header text="Prompt Template" />
      <Text>
        This is the prompt send to GPT when asking for {config.type} for a
        reclip. The string "{config.placeholder}" is replaced with the actual
        transcript.
      </Text>
      <TextArea
        value={config.template}
        onChange={handleChange.bind(null, 'template')}
        rows={10}
      />
    </Box>
  )
}

export default ConfigEditor
