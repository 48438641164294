import { useMemo } from 'react'
import Box from '@mui/material/Box'

const Message = ({ text, buttons = {} }) => {
  const renderedText = useMemo(() => {
    const buttonKeys = Object.keys(buttons || {})

    let rendered = text

    buttonKeys.forEach((buttonKey) => {
      const button = buttons[buttonKey]
      const link = `<br/><a href=${button.link.url} target="_blank">${button.text}</a></br>`
      rendered = rendered.replace(`%%button:${buttonKey}%%`, link)
    })

    return rendered
  }, [text, buttons])

  return (
    <Box
      sx={{
        '& a': {
          textDecoration: 'none',
          backgroundColor: 'primary.main',
          cursor: 'pointer',
          padding: '0.5em 1em',
          borderRadius: '1em',
          color: 'background.default',
          fontWeight: 700,
          display: 'inline-block',
          my: 1,
        },
      }}
      dangerouslySetInnerHTML={{ __html: renderedText }}
    />
  )
}

export default Message
