import Calendar from 'rsuite/Calendar'
import 'rsuite/dist/rsuite-no-reset.min.css'
import Box from '@mui/material/Box'
import Legend from './Legend'
import * as dayjs from 'dayjs'
import useModalsActions from 'store/actions/modals'

const colors = {
  SCHEDULED: 'yellow',
  ARCHIVED: 'orange',
}

function broadcastsOnDate(date, broadcasts) {
  return broadcasts.filter((broadcast) => {
    return dayjs(date).isSame(dayjs(broadcast.scheduled_for), 'day')
  })
}

const CalendarItem = ({ name, status, onClick }) => {
  const circle = (
    <Box
      sx={{
        width: 10,
        height: 10,
        minWidth: 10,
        minHeight: 10,
        borderRadius: '50%',
        mr: 1,
        flex: 0,
        backgroundColor:
          status === 'SCHEDULED' ? colors.SCHEDULED : colors.ARCHIVED,
      }}
    />
  )
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      {circle}
      <Box
        sx={{
          cursor: 'pointer',
          textAlign: 'left',
          '&:hover': { textDecoration: 'underline' },
        }}
        onClick={onClick}
      >
        {name}
      </Box>
    </Box>
  )
}

const BroadcastCalendar = ({ broadcasts }) => {
  const { openModal } = useModalsActions()

  function renderCell(date) {
    const list = broadcastsOnDate(date, broadcasts)

    return (
      <Box sx={{ mt: 1, ml: 1, maxHeight: 60, overflow: 'auto' }}>
        {list.map((broadcast, index) => (
          <CalendarItem
            key={index.toString()}
            name={broadcast.name}
            status={broadcast.status}
            onClick={openModal.bind(null, 'message', {
              message: broadcast.message,
            })}
          />
        ))}
      </Box>
    )
  }

  return (
    <Box
      sx={{
        maxHeight: '900px',
        overflow: 'auto',
        position: 'relative',
        '.rs-calendar-table-cell-content': {
          borderWidth: 1,
          borderColor: 'divider',
          borderStyle: 'solid',
          marginBottom: '3px',
          maxHeight: '100px',
        },
        '.rs-calendar-table-cell-content:hover': {
          cursor: 'default',
          backgroundColor: 'transparent',
        },
        '.rs-calendar-table-cell-selected .rs-calendar-table-cell-content': {
          boxShadow: 'inset 0 0 0 1px #f7f7fa00',
        },
        '.rs-calendar-table-cell.rs-calendar-table-cell-un-same-month': {
          visibility: 'hidden',
        },
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 16,
          left: '50%',
          transform: 'translateX(-50%)',
        }}
      >
        <Legend colors={colors} />
      </Box>
      <Calendar
        className="rs-theme-dark"
        renderCell={renderCell}
        isoWeek={false}
      />
    </Box>
  )
}

export default BroadcastCalendar
