import Box from '@mui/material/Box'
import { useParams } from 'react-router-dom'
import Player from 'components/core/ReclipPlayer'

const ReclipPlayerPage = () => {
  const params = useParams()

  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          width: 'calc(90vh * 9 / 16)',
          height: '90vh',
          borderRadius: '12px',
          overflow: 'hidden',
          backgroundColor: 'background.paper',
        }}
      >
        <Player source_id={params.source_id} />
      </Box>
    </Box>
  )
}

export default ReclipPlayerPage
