import { useCallback } from 'react'
import Dialog from '@mui/material/Dialog'
import Box from '@mui/material/Box'
import useModalsActions from 'store/actions/modals'
import { useModal } from 'store/selectors'
import Button from '@mui/material/Button'

const ConfirmDeleteBroadcastModal = () => {
  const {
    isOpen,
    params: { onConfirm },
  } = useModal('confirmDeleteBroadcast')
  const { closeModal } = useModalsActions()

  const doClose = useCallback(() => {
    closeModal('confirmDeleteBroadcast')
  }, [])

  const confirm = useCallback(() => {
    onConfirm()
    doClose()
  }, [onConfirm])

  return (
    <Dialog
      open={isOpen}
      sx={{ '& .MuiDialog-paper': { backgroundColor: 'background.default' } }}
      onClose={doClose}
    >
      <Box sx={{ p: 4 }}>
        <Box sx={{ mb: 4, textAlign: 'center' }}>
          <Box sx={{ fontWeight: 700, mb: 1 }}>Delete broadcast?</Box>
          <Box>This can't be undone.</Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Button variant="contained" onClick={confirm}>
            Delete
          </Button>
          <Button sx={{ ml: 4 }} variant="outlined" onClick={doClose}>
            Cancel
          </Button>
        </Box>
      </Box>
    </Dialog>
  )
}

export default ConfirmDeleteBroadcastModal
