import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import Layout from './Layout'
import Chat from './Chat'
import Networks from './Networks'
import Staff from './Staff'
import Search from './Search'
import Deepgram from './Deepgram'
import Swagger from './Swagger'
import Settings from './Settings'
import ReclipsMap from './ReclipsMap'
import ReclipPlayer from './ReclipPlayer'
import GPT from './GPT'

const Main = () => (
  <Layout>
    <Routes>
      <Route path="/chat/*" element={<Chat />} />
      <Route path="/networks" element={<Networks />} />
      <Route path="/staff" element={<Staff />} />
      <Route path="/search" element={<Search />} />
      <Route path="/transcription" element={<Deepgram />} />
      <Route path="/swagger/*" element={<Swagger />} />
      <Route path="/map" element={<ReclipsMap />} />
      <Route path="/r/:source_id" element={<ReclipPlayer />} />
      <Route path="/settings" element={<Settings />} />
      <Route path="/gpt" element={<GPT />} />
      <Route path="*" element={<Navigate to="/chat" replace />} />
    </Routes>
  </Layout>
)

export default Main
