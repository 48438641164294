import * as user from './user'
import * as chat from './chat'
import * as broadcasts from './broadcasts'
import * as networks from './networks'
import * as users from './users'
import * as staff from './staff'
import * as search from './search'
import * as deepgram from './deepgram'
import * as map from './map'
import * as reclips from './reclips'
import * as openai from './openai'

const api = {
  user,
  chat,
  broadcasts,
  networks,
  users,
  staff,
  search,
  deepgram,
  map,
  reclips,
  openai,
}

export default api
