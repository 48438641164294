import CopyIcon from '@mui/icons-material/CopyAllOutlined'
import TooltipIcon from './Tooltip'

const CopyToClipboardButton = ({ data, title }) => {
  const handleClick = () => {
    navigator.clipboard.writeText(data)
  }

  return <TooltipIcon Icon={CopyIcon} title={title} onClick={handleClick} />
}

export default CopyToClipboardButton
