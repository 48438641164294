import Box from '@mui/material/Box'
import { useMemo } from 'react'

const PlayingOverlay = ({ pause, reclip, time }) => {
  const caption = useMemo(() => {
    if (!reclip.transcription) return null

    const { blocks } = reclip.transcription
    for (let i = 0; i < blocks.length; i++) {
      const { start, end, text } = blocks[i]
      if (time > start && time < end) return text
    }

    return null
  }, [reclip, time])

  const image = useMemo(() => {
    const { images } = reclip
    if (!images) return null

    for (let i = 0; i < images.length; i++) {
      const { start, end } = images[i]
      if (time > start && time < end) return images[i]
    }

    return null
  }, [reclip, time])

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
      }}
      onClick={pause}
    >
      <Box
        sx={{
          fontSize: '3em',
          fontWeight: 700,
          textTransform: 'uppercase',
          px: 6,
          position: 'absolute',
          top: '50%',
          left: 0,
          right: 0,
          transform: 'translateY(-50%)',
          zIndex: 2,
          textAlign: 'center',
        }}
      >
        {caption}
      </Box>
      {image && (
        <Box
          sx={{
            position: 'absolute',
            top: `${image.position.y * 100}%`,
            left: `${image.position.x * 100}%`,
            width: '100%',
            transform: `translate(-50%, -50%) rotate(${image.transform.rotation}rad) scale(${image.transform.scale})`,
            zIndex: 1,
            '& img': {
              width: '100%',
            },
          }}
        >
          <img src={image.url} alt="gif" />
        </Box>
      )}
    </Box>
  )
}

export default PlayingOverlay
