import Box from '@mui/material/Box'
import { toDateString, toTimeString } from 'services/utils'
import ViewButton from './ViewButton'

const PendingList = ({ broadcasts }) => {
  return (
    <Box
      sx={{
        maxHeight: '600px',
        overflow: 'auto',
        fontSize: '0.9em',
        '& table': {
          borderCollapse: 'collapse',
          width: '100%',
        },
        '& th, td': {
          padding: 1,
          textAlign: 'left',
        },
        '& tbody tr:not(:last-of-type) td': {
          borderBottomWidth: 1,
          borderBottomColor: 'divider',
          borderBottomStyle: 'solid',
        },
        '& tbody tr td': {
          cursor: 'default',
        },
        '& th': {
          borderBottomWidth: 1,
          borderBottomColor: 'divider',
          borderBottomStyle: 'solid',
          whiteSpace: 'nowrap',
        },
      }}
    >
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Started At</th>
            <th>Sender</th>
            <th>Segment Size</th>
            <th>Num Sent</th>
            <th>Num Errors</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {broadcasts.map((broadcast) => {
            const {
              id,
              name,
              segment_size,
              scheduled_for,
              message,
              sent_messages,
              errored_messages,
            } = broadcast
            return (
              <tr key={id}>
                <td>{name}</td>
                <td>
                  <Box sx={{ whiteSpace: 'nowrap' }}>
                    {toDateString(scheduled_for) +
                      ' @ ' +
                      toTimeString(scheduled_for)}
                  </Box>
                </td>
                <td>{message.custom_data.staff_member}</td>
                <td>{segment_size}</td>
                <td>{sent_messages}</td>
                <td>{errored_messages}</td>
                <td>
                  <ViewButton message={message} />
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
      {broadcasts.length === 0 && (
        <Box sx={{ fontStyle: 'italic', mt: 4 }}>
          No broadcasts in progress.
        </Box>
      )}
    </Box>
  )
}

export default PendingList
