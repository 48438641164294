import Profile from './Profile'

const Modals = () => {
  return (
    <>
      <Profile />
    </>
  )
}

export default Modals
