import { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import api from 'services/api'
import Loader from 'components/core/Loader'
import Message from './Message'

const WelcomeMessages = () => {
  const [messages, setMessages] = useState(null)
  const [error, setError] = useState(null)

  useEffect(() => {
    api.chat
      .getWelcomeMessages()
      .then(setMessages)
      .catch((e) => setError(e.message))
  }, [])

  if (!messages) return <Loader text="Loading messages" error={error} />

  return (
    <Box sx={{ p: 6, pt: 10, textAlign: 'center' }}>
      <Box sx={{ width: 800, maxWidth: '100%', margin: '0 auto' }}>
        {messages.map((message) => (
          <Box key={message.id} sx={{ mb: 6 }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                fontWeight: 700,
                borderBottomWidth: 1,
                borderBottomColor: 'divider',
                borderBottomStyle: 'solid',
                pb: 1,
              }}
            >
              <Box>Message {message.message_number}</Box>
              <Box>Schedule: {message.schedule_hours} hours</Box>
            </Box>
            {message.variants.map((variant) => (
              <Box key={variant.variant_id} sx={{ mt: 3 }}>
                <Box
                  sx={{
                    whiteSpace: 'pre-line',
                    backgroundColor: 'background.paper',
                    p: 1.5,
                    borderRadius: '1em',
                    textAlign: 'left',
                  }}
                >
                  <Message
                    text={variant.text}
                    buttons={variant.custom_data?.buttons}
                  />
                </Box>
                <Box
                  sx={{
                    fontSize: '0.9em',
                    color: 'text.secondary',
                    mt: 0.5,
                    textAlign: 'right',
                  }}
                >
                  variant_id: {variant.variant_id}
                </Box>
              </Box>
            ))}
          </Box>
        ))}
      </Box>
    </Box>
  )
}

export default WelcomeMessages
