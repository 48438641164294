import axios from 'axios'
import { ADMIN_API_URL } from 'const'

// NOTE: these requests have to pass through the admin api because
// the broadcasts server is not https-enabled
// const BASE_URL = `${BROADCASTS_API_URL}/broadcasts`

const BASE_URL = `${ADMIN_API_URL}/broadcasts`

export const listBroadcasts = async (limit = 100) => {
  try {
    const url = `${BASE_URL}?limit=${limit}`
    const { data } = await axios.get(url)
    return data
  } catch (err) {
    throw new Error(err.response?.data?.detail || err.message)
  }
}

export const listPendingBroadcasts = async () => {
  try {
    const url = `${BASE_URL}/pending`
    const { data } = await axios.get(url)
    return data
  } catch (err) {
    throw new Error(err.response?.data?.detail || err.message)
  }
}

export const createBroadcast = async ({
  name,
  message,
  segment_type,
  scheduled_for,
  user_ids,
}) => {
  try {
    const url = BASE_URL
    const { data } = await axios.post(url, {
      name,
      message,
      segment_type,
      scheduled_for,
      user_ids,
    })
    return data
  } catch (err) {
    throw new Error(err.response?.data?.detail || err.message)
  }
}

export const deleteBroadcast = async (broadcastId) => {
  try {
    const url = `${BASE_URL}/${broadcastId}`
    const { data } = await axios.delete(url)
    return data
  } catch (err) {
    throw new Error(err.response?.data?.detail || err.message)
  }
}
