import * as React from 'react'
import { styled } from '@mui/material/styles'
import IconButton from '@mui/material/IconButton'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    color: 'text.primary',
    fontSize: '0.9em',
    fontWeight: 700,
  },
}))

const TooltipIcon = ({ Icon, title, onClick }) => (
  <StyledTooltip title={title} placement="top">
    <IconButton size="small" onClick={onClick}>
      <Icon sx={{ fontSize: '0.9em' }} />
    </IconButton>
  </StyledTooltip>
)

export default TooltipIcon
