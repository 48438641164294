import { useCallback, useState } from 'react'
import Box from '@mui/material/Box'
import { NetworkIcon } from 'components/core/Icons'
import IconButton from '@mui/material/IconButton'
// import BackIcon from '@mui/icons-material/ChevronLeft'
import NetworkOverview from './Overview'
import SelectedNode from './SelectedNode'
import SelectedLink from './SelectedLink'
import { useSelectedNode, useSelectedLink } from 'store/selectors'
import useNetworksActions from 'store/actions/networks'

const NetworkExplorer = () => {
  const selectedNode = useSelectedNode()
  const selectedLink = useSelectedLink()
  const { selectNode, selectLink } = useNetworksActions()
  const [slide, setSlide] = useState(false)

  const handleBack = useCallback(() => {
    setSlide(true)
    setTimeout(() => {
      if (!!selectedNode) selectNode(null)
      else selectLink(null)

      setSlide(false)
    }, 250)
  }, [selectedNode, selectedLink, selectNode, selectLink])

  return (
    <Box sx={{ height: '100%' }}>
      <Box
        sx={{
          height: '100%',
          overflow: 'hidden',
          position: 'relative',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            display: 'flex',
            height: '100%',
            width: '200%',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            transform:
              (selectedNode || selectedLink) && !slide
                ? 'translateX(-50%)'
                : undefined,
            transition:
              selectedNode || selectedLink || slide
                ? 'transform 0.25s ease-out'
                : undefined,
          }}
        >
          <Box className="overview" sx={{ width: '50%' }}>
            <NetworkOverview />
          </Box>
          <Box className="user" sx={{ width: '50%', position: 'relative' }}>
            {(selectedNode || selectedLink) && (
              <>
                <Box
                  sx={{
                    position: 'absolute',
                    top: 10,
                    left: 10,
                    zIndex: 1,
                  }}
                >
                  <IconButton onClick={handleBack}>
                    <NetworkIcon fontSize="0.7em" />
                  </IconButton>
                </Box>
                {selectedNode ? <SelectedNode /> : <SelectedLink />}
              </>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default NetworkExplorer
