import { useState, useCallback, useEffect, useRef } from 'react'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import SearchIcon from '@mui/icons-material/Search'
import api from 'services/api'
import { useAuth } from 'store/selectors'
import Highlighter from 'react-highlight-words'
import Preview from './Preview'
import { toDateString } from '../Networks/utils'
import InfiniteScroll from 'react-infinite-scroll-component'
import CopyToClipboardButton from 'components/core/CopyToClipboard'

const HEADER_HEIGHT = 115

const Search = () => {
  const [searchParams, setSearchParams] = useState({
    searchTerm: '',
    page: 0,
    limitSearchToUser: false,
  })

  const [items, setItems] = useState([])
  const [results, setResults] = useState(null)
  const { user } = useAuth()
  const scrollableDiv = useRef(null)
  const [roundTripTime, setRoundTripTime] = useState(null)

  useEffect(() => {
    const { searchTerm, page, limitSearchToUser } = searchParams

    const apiCall = limitSearchToUser
      ? api.search.searchMyReclips
      : api.search.searchReclips

    const now = Date.now()

    apiCall(searchTerm, page).then((results) => {
      setResults(results)
      setItems((items) => {
        if (page === 0) {
          scrollableDiv.current.scrollTop = 0
          return results.hits
        } else {
          return [...items, ...results.hits]
        }
      })
      setRoundTripTime(Date.now() - now)
    })
  }, [searchParams])

  const handleSearchTermChange = useCallback((e) => {
    setSearchParams((params) => ({
      ...params,
      searchTerm: e.target.value,
      page: 0,
    }))
  }, [])

  const handleToggleLimit = useCallback((event) => {
    setSearchParams((params) => ({
      ...params,
      limitSearchToUser: event.target.checked,
      page: 0,
    }))
  }, [])

  const handleNextPage = useCallback(() => {
    setSearchParams((params) => ({
      ...params,
      page: params.page + 1,
    }))
  }, [])

  return (
    <Box sx={{ height: '100%', overflow: 'hidden', width: '100%' }}>
      <Box
        sx={{
          width: '100%',
          height: `${HEADER_HEIGHT}px`,
          borderBottomWidth: 1,
          borderBottomColor: 'divider',
          borderBottomStyle: 'solid',
        }}
      >
        <Box
          sx={{
            px: 2,
            backgroundColor: 'background.paper',
            display: 'flex',
            alignItems: 'center',
            '& > input': {
              ml: 2,
              height: '60px',
              flex: 1,
              border: 0,
              outline: 0,
              backgroundColor: 'transparent',
              color: 'text.primary',
              fontSize: '20px',
            },
          }}
        >
          <SearchIcon sx={{ fontSize: 32 }} />
          <input
            type="text"
            value={searchParams.searchTerm}
            onChange={handleSearchTermChange}
            placeholder={
              searchParams.limitSearchToUser
                ? `search @${user.username}'s reclips`
                : 'search reclips'
            }
            autoFocus
          />
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box>Search @{user.username}'s reclips only</Box>
            <Checkbox
              sx={{ ml: 2 }}
              checked={searchParams.limitSearchToUser}
              onChange={handleToggleLimit}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          </Box>
        </Box>
        {results !== null && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              p: 2,
              fontStyle: 'italic',
            }}
          >
            <Box sx={{ fontStyle: 'italic' }}>
              Showing {items.length} {items.length === 1 ? 'reclip' : 'reclips'}{' '}
              out of {results.num_hits}
            </Box>
            <Box>response time: {roundTripTime} ms</Box>
          </Box>
        )}
      </Box>
      <Box
        sx={{ height: `calc(100% - ${HEADER_HEIGHT}px)`, overflow: 'auto' }}
        id="scrollableDiv"
        ref={scrollableDiv}
      >
        {results !== null && (
          <InfiniteScroll
            dataLength={items.length}
            next={items.length === 0 ? undefined : handleNextPage}
            hasMore={items.length < results.num_hits}
            scrollableTarget="scrollableDiv"
            scrollThreshold={0.6}
          >
            {items.map((item, index) => (
              <Box
                sx={{
                  p: 2,
                  borderBottomColor: 'divider',
                  borderBottomWidth: 1,
                  borderBottomStyle: 'solid',
                  display: 'flex',
                }}
                key={index.toString()}
              >
                <Preview reclip={item} />
                <Box sx={{ flex: 1, ml: 2 }}>
                  {/* <Box>
                    <b>Title: </b>
                    {item.title ? (
                      <Highlighter
                        searchWords={[searchParams.searchTerm]}
                        textToHighlight={item.title || 'New Reclip'}
                      />
                    ) : (
                      'New Reclip'
                    )}
                  </Box> */}
                  <Box>
                    <b>Title: </b>
                    {(item.title || item.title_auto) && (
                      <Highlighter
                        searchWords={[searchParams.searchTerm]}
                        textToHighlight={item.title || item.title_auto}
                      />
                    )}
                  </Box>
                  <Box>
                    <b>Username: </b>@{item.username}
                  </Box>
                  <Box>
                    <b>Created on: </b>
                    {toDateString(item.created_at)}
                  </Box>
                  <Box>
                    <b>Transcript </b>
                    <CopyToClipboardButton
                      title="Copy transcript"
                      data={item.text}
                    />
                    <br />
                    {item.text && (
                      <Highlighter
                        searchWords={[searchParams.searchTerm]}
                        textToHighlight={item.text}
                      />
                    )}
                  </Box>
                </Box>
              </Box>
            ))}
          </InfiniteScroll>
        )}
      </Box>
    </Box>
  )
}

export default Search
