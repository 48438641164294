import { useEffect, useCallback } from 'react'
import Box from '@mui/material/Box'
import { useChatContext } from 'stream-chat-react'
import Channel from '../../Channel'
import { BROADCAST_CHANNEL_ID } from 'const'
import MessageContext from 'components/Main/Chat/MessageContext'

const GetMessage = ({ setMessage }) => {
  const { client, setActiveChannel } = useChatContext()

  // extract text and attachments from streamchat's messageInput
  const onChangeMessageInput = useCallback((messageInput) => {
    const { text, fileUploads, imageUploads } = messageInput

    const files = Object.values({ ...fileUploads, ...imageUploads })
    files.sort((a, b) => b.file.lastModified - a.file.lastModified)

    const finished = files.filter((file) => file.state === 'finished')

    const attachments = finished.map((file) => {
      const type = file.file.type.split('/')[0]
      const attachment = { type, asset_url: file.url }

      if (type === 'image') attachment.image_url = file.url

      return attachment
    })

    setMessage({
      text,
      attachments,
      isReady: text.length > 0 && finished.length === files.length,
    })
  }, [])

  useEffect(() => {
    if (!client) return

    let channel

    const createChannel = async () => {
      channel = await client.channel('messaging', BROADCAST_CHANNEL_ID, {
        name: 'Broadcast Channel',
      })

      setActiveChannel(channel)
      await channel.watch()
    }

    createChannel()

    return () => {
      setActiveChannel(null)
    }
  }, [client, setActiveChannel])

  return (
    <Box>
      <MessageContext.Provider value={onChangeMessageInput}>
        <Channel inputOnly />
      </MessageContext.Provider>
    </Box>
  )
}

export default GetMessage
