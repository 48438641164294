import axios from 'axios'
import { ADMIN_API_URL } from 'const'

const BASE_URL = `${ADMIN_API_URL}/openai`

export const getMetadata = async () => {
  try {
    const { data } = await axios.get(BASE_URL)
    return data
  } catch (err) {
    throw new Error(err.response?.data?.detail || err.message)
  }
}

export const getConfig = async (configType) => {
  try {
    const { data } = await axios.get(`${BASE_URL}/${configType}`)
    return data
  } catch (err) {
    throw new Error(err.response?.data?.detail || err.message)
  }
}

export const testConfig = async (text, config) => {
  try {
    const { data } = await axios.post(BASE_URL + '/test', { text, config })
    return data
  } catch (err) {
    throw new Error(err.response?.data?.detail || err.message)
  }
}

export const saveConfig = async (config) => {
  try {
    const { data } = await axios.put(BASE_URL, config)
    return data
  } catch (err) {
    throw new Error(err.response?.data?.detail || err.message)
  }
}
