import Box from '@mui/material/Box'

const Attachment = ({ attachment }) => {
  if (attachment.type === 'image')
    return (
      <Box
        sx={{
          '& img': {
            width: 200,
          },
        }}
      >
        <img src={attachment.image_url} />
      </Box>
    )

  if (attachment.type === 'video')
    return (
      <Box
        sx={{
          '& video': {
            width: 200,
          },
        }}
      >
        <video controls>
          <source src={attachment.asset_url} />
        </video>
      </Box>
    )

  return null
}

const Message = ({ message }) => {
  return (
    <Box
      sx={{
        width: 400,
        maxHeight: 600,
        overflow: 'auto',
      }}
    >
      <Box sx={{ fontWeight: 700, mb: 1 }}>Message Text</Box>
      <Box sx={{ whiteSpace: 'pre-line' }}>{message.text}</Box>
      <Box sx={{ fontWeight: 700, mt: 4, mb: 1 }}>Attachments</Box>
      {message.attachments.length === 0 && (
        <Box sx={{ fontStyle: 'italic' }}>No attachments</Box>
      )}
      {message.attachments.map((attachment, index) => (
        <Attachment key={index.toString()} attachment={attachment} />
      ))}
    </Box>
  )
}

export default Message
